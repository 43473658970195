// Example: Load Rails libraries in Vite.
import Rails from "@rails/ujs"
import "trix"
import "@rails/actiontext"

import * as Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";

// Import all channels.
import.meta.glob("./**/*_channel.js",{ eager: true })

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import { noLeftScroll } from "./legacy/prevent_left_scroll";
noLeftScroll();

import { dateFlatpickr } from "./legacy/date_flatpickr";
dateFlatpickr();

import { tr_link } from "./legacy/tr_link";
import { task_check } from "./legacy/task_check";
import { students_presences_check } from "./legacy/students_presences_check";
import { paid_or_not_icons } from "./legacy/change_paid_or_not_icons";
import { events_staffs_visible_to_staffs } from "./legacy/change_visible_or_secret_icons";
import { change_availability_mode_icons } from "./legacy/change_availability_mode_icons";
import { initSelect2 } from "./legacy/init_select2";
import { addFieldStaff } from "./legacy/add_field_staff";
import { addFieldLocation } from "./legacy/add_field_location";
import { addFieldCoursesEventExercise } from "./legacy/add_field_courses_event_exercise";
import { addFieldCoursePlanSection } from "./legacy/add_field_course_plan_section";
import { addFieldCoursePlanSectionExercise } from "./legacy/add_field_course_plan_section_exercise";
import { moveUpFieldForm, moveDownFieldForm } from "./legacy/move_form_section";
import { removeFieldForm } from "./legacy/remove_field_form";
import { removeTableRow } from "./legacy/remove_table_row";
import { escapeListener } from "./legacy/escape_listener";
import { parallaxRocket } from "./legacy/parallax";
import { unfoldPlanCourses } from "./legacy/unfold_plan_courses";
import { courseSubmitFormValidation } from "./legacy/course_submit_form_validation";
import { indexSearch } from "./legacy/index_search";
import { initializeSortableTables } from "./legacy/sortable_tables";
import { addFieldEventsStaffAvailability } from './legacy/add_events_staff_availability';

document.addEventListener("turbolinks:load", function () {
  parallaxRocket();
  tr_link();
  initSelect2();
  addFieldStaff();
  addFieldLocation();
  addFieldCoursesEventExercise();
  addFieldCoursePlanSection();
  addFieldCoursePlanSectionExercise();
  addFieldEventsStaffAvailability();
  moveUpFieldForm();
  moveDownFieldForm();
  removeFieldForm();
  removeTableRow();
  escapeListener();
  task_check();
  students_presences_check();
  unfoldPlanCourses();
  paid_or_not_icons();
  courseSubmitFormValidation();
  indexSearch();
  initializeSortableTables();
  events_staffs_visible_to_staffs();
  change_availability_mode_icons();

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
});
