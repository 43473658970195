const change_availability_mode_icons = () => {
  const allEventsStaffAvailabilityModeIconsLinks = document.querySelectorAll("#availability-mode-icon");
  Array.from(allEventsStaffAvailabilityModeIconsLinks).forEach((icon) => {
    icon.addEventListener("click", function (event) {
      console.log(event.target);
      const AvailabilityModeIcons = event.target.closest("#availability-mode-links").querySelectorAll("#availability-mode-icon");
      Array.from(AvailabilityModeIcons).forEach((icon) => {
        if (icon === event.target) {
          if (icon.classList.contains('inactive')) {
            icon.classList.replace('inactive', 'active');
          }
        } else {
          icon.classList.replace('active', 'inactive')
        }
      });
    });
  });
};

export { change_availability_mode_icons };
