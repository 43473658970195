const events_staffs_visible_to_staffs = () => {
  const allEventsStaffVisibleOrSecretIconsLinks = document.querySelectorAll("#visible-or-secret-icon");
  Array.from(allEventsStaffVisibleOrSecretIconsLinks).forEach((icon) => {
    icon.addEventListener("click", function (event) {
      console.log(event.target);
      const visibleAndSecretIcons = event.target.closest("#visible-or-secret-links").querySelectorAll("#visible-or-secret-icon");
      Array.from(visibleAndSecretIcons).forEach((icon) => {
        if (icon === event.target) {
          if (icon.classList.contains('inactive')) {
            icon.classList.replace('inactive', 'active');
          }
        } else {
          icon.classList.replace('active', 'inactive')
        }
      });
    });
  });
};

export { events_staffs_visible_to_staffs };
