import $ from "jquery";

const addFieldEventsStaffAvailability = () => {
  $("form").on("click", ".add_events_staff_availability_field", function (event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data("id"), "g");
    let selector = '.events_staff_availability_fields';
    $(selector).append($(this).data("fields").replace(regexp, time));
    $(selector).find('.select2').select2();

    $(selector).find("> tr:last > td > input.order_field").each(function(index) {
      $(this).val($(selector).find("> tr").length);
    });

    return event.preventDefault();
  });
};

export { addFieldEventsStaffAvailability };
